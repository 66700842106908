/* EnquireButton.css */

/* Enquire Now Button fixed on the right side of the page */
.enquire-now-btn {
    position: fixed;
    right: -70px; /* Adjusted to be flush with the edge */
    top: 50%; /* Start at the middle of the screen */
    transform: translateY(-50%) rotate(90deg); /* Center the button vertically and rotate */
    background-color: #5c3c7b; /* Purple button color */
    color: #ffd105; /* Yellow text color */
    padding: 10px 20px; /* Default padding */
    text-decoration: none; /* Prevent underlining */
    font-size: 18px; /* Larger font size for visibility */
    font-weight: 600; /* Bolder text for emphasis */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    transition: background-color 0.3s, color 0.3s, transform 0.3s; /* Transition for hover effects */
    z-index: 1000; /* Ensure it stays above other content */
    cursor: pointer; /* Cursor pointer for clickable element */
    display: flex; /* Use flex for better alignment */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
}

/* Additional styles to create a vertical stack effect */
.enquire-now-btn-container {
    display: flex;
    flex-direction: column; /* Stack vertically */
    align-items: flex-end; /* Align to the right */
    position: fixed;
    right: 0; /* Keep it flush with the right edge */
    top: 50%; /* Start at the middle of the screen */
    transform: translateY(-50%); /* Center the button vertically */
}

/* Hover effect */
.enquire-now-btn:hover {
    background-color: #ae92c8; /* Lighter purple background on hover */
    color: #000000; /* Black text on hover */
    text-decoration: none; /* Ensure no underline on hover */
    transform: translateY(-2px) rotate(90deg); /* Slight lift on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .enquire-now-btn {
        padding: 12px 20px; /* Slightly smaller on mobile */
        font-size: 16px; /* Adjust font size for smaller screens */
        right: -50px; /* Adjust position for better visibility */
    }
}

/* Adjust for smaller devices */
@media (max-width: 480px) {
    .enquire-now-btn {
        padding: 10px 25px; /* Even smaller padding for very small devices */
        font-size: 14px; /* Adjust font size further for small screens */
        right: -30px; /* Move closer to the edge for better accessibility */
    }
}

/* Accessibility improvements */
.enquire-now-btn:focus {
    outline: 3px solid #ffd105; /* Outline for accessibility when focused */
    text-decoration: none; /* Prevent underline when focused */
}
