/* Property Details Container */
.commercial-property-details {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Property Description Section */
.commercial-property-description {
    margin-top: 20px;
    background: rgba(145, 136, 96, 0.2); /* Light Yellow background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.commercial-property-description h2 {
    font-size: 1.8rem;
    color: #5c3c7b; /* Purple */
    margin-bottom: 10px;
}

.commercial-property-description p {
    font-size: 1rem;
    color: #333;
    line-height: 1.6;
}

/* Carousel Styles */
.commercial-carousel {
    position: relative;
    max-width: 100%;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 10px;
}

.commercial-carousel-images {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.commercial-carousel-image {
    min-width: 100%;
    height: auto;
    display: none;
    transition: opacity 0.5s ease-in-out;
}

.commercial-carousel-image.active {
    display: block;
    opacity: 1;
}

.commercial-carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 10;
}

.commercial-carousel-button.left {
    left: 10px;
}

.commercial-carousel-button.right {
    right: 10px;
}

.commercial-carousel-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

/* Property Title */
.commercial-property-title {
    font-size: 2.5rem;
    margin: 10px 0;
    color: #5c3c7b; /* Purple */
    text-align: center;
    position: relative;
}

.commercial-property-title::after {
    content: '';
    display: block;
    height: 4px;
    width: 50px;
    background: #ffd105; /* Yellow */
    margin: 10px auto;
    border-radius: 2px;
}

/* Marquee Styles */
.commercial-marquee {
    background-color: #5c3c7b; /* Purple */
    color: #ffd105;
    padding: 10px;
    border-radius: 5px;
    margin: 20px 0;
    overflow: hidden;
    white-space: nowrap;
}

.commercial-marquee-content {
    display: inline-block;
    animation: commercial-marquee 15s linear infinite;
}

@keyframes commercial-marquee {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
}

/* Highlights Section */
.commercial-highlights-section {
    margin-top: 20px;
}

.commercial-highlights-section h2 {
    font-size: 1.8rem;
    color: #5c3c7b; /* Purple */
    margin-bottom: 20px;
    border-bottom: 2px solid #ffd105; /* Yellow underline */
    padding-bottom: 5px;
    text-align: center;
}

/* Highlights List as Grid */
.commercial-highlights-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

/* Highlight Card Styles */
.commercial-highlight-card {
    background: rgba(145, 136, 96, 0.2); /* Light Yellow background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: background 0.3s, transform 0.3s;
}

.commercial-highlight-card:hover {
    background: #2a0c46; /* Darker Yellow on hover */
    transform: translateY(-5px);
}

.commercial-highlight-card:hover p {
    color: white;
}

.commercial-highlight-icon {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #5c3c7b; /* Purple */
}

.commercial-highlight-card p {
    font-size: 1rem;
    color: #333;
}

/* Property Table */
.commercial-table-container {
    margin-top: 40px;
}

.commercial-table-container h2 {
    font-size: 1.8rem;
    color: #5c3c7b; /* Purple */
    margin-bottom: 10px;
    border-bottom: 2px solid #ffd105; /* Yellow underline */
    padding-bottom: 5px;
    text-align: center;
}

.commercial-property-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffd105; /* Light Yellow background */
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.commercial-property-table th,
.commercial-property-table td {
    padding: 15px;
    text-align: left;
    color: #333;
    border: 1px solid #ddd;
}

.commercial-property-table th {
    background-color: #5c3c7b; /* Purple */
    color: #fff;
    font-weight: bold;
}

.commercial-property-table tbody tr:nth-child(odd) {
    background-color: rgba(255, 209, 5, 0.2); /* Light Yellow */
}

.commercial-property-table tbody tr:hover {
    background-color: rgba(255, 209, 5, 0.5); /* Darker Yellow on hover */
}

/* Download Brochure Button */
.commercial-download-brochure-btn {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #5c3c7b; /* Purple */
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s;
}

.commercial-download-brochure-btn:hover {
    background-color: #4b2c5f; /* Darker Purple */
}

.commercial-download-brochure-btn a {
    text-decoration: none;
    color: inherit;
}

/* Media Queries */
@media (max-width: 1024px) {
    .commercial-property-title {
        font-size: 2rem;
    }

    .commercial-property-description h2 {
        font-size: 1.6rem;
    }

    .commercial-property-description p {
        font-size: 0.9rem;
    }

    .commercial-carousel-button {
        padding: 8px;
    }

    .commercial-highlights-section h2 {
        font-size: 1.6rem;
    }

    .commercial-highlight-card {
        padding: 15px;
    }

    .commercial-highlight-icon {
        font-size: 1.5rem;
    }

    .commercial-property-table th,
    .commercial-property-table td {
        padding: 12px;
    }

    .commercial-download-brochure-btn {
        padding: 8px 16px;
        font-size: 0.9rem;
    }
}

@media (max-width: 768px) {
    .commercial-highlights-list {
        grid-template-columns: 1fr;
    }

    .commercial-property-table th,
    .commercial-property-table td {
        padding: 10px;
        font-size: 0.9rem;
    }

    .commercial-carousel-button {
        font-size: 0.8rem;
    }

    .commercial-property-title {
        font-size: 1.8rem;
    }
}

@media (max-width: 480px) {
    .commercial-property-title {
        font-size: 1.5rem;
    }

    .commercial-property-description h2 {
        font-size: 1.4rem;
    }

    .commercial-property-description p {
        font-size: 0.8rem;
    }

    .commercial-carousel-button {
        padding: 5px;
    }

    .commercial-download-brochure-btn {
        padding: 6px 12px;
        font-size: 0.8rem;
    }

    .commercial-marquee {
        font-size: 0.9rem;
    }
}
