.footer {
    position: relative; /* Position relative for the background */
    color: #fff; /* White text */
    padding: 40px 20px;
    font-family: 'Arial', sans-serif;
    background-image: url('../assets/emi12.jpg'); /* Background image */
    background-size: cover; /* Cover the entire footer */
    background-position: center; /* Center the image */
    overflow: hidden; /* Prevent overflow */
}

.footer::before {
    content: ''; /* Pseudo-element for background blur */
    position: absolute; /* Position it absolutely */
    top: 0; /* Align to top */
    left: 0; /* Align to left */
    right: 0; /* Align to right */
    bottom: 0; /* Align to bottom */
    background: rgba(0, 0, 0, 0.8); /* Dark overlay for readability */
    filter: blur(8px); /* Blur effect */
    z-index: 0; /* Behind other content */
}

.footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative; /* Relative position for child elements */
    z-index: 1; /* Bring content above the blur */
}

.footer-section {
    flex: 1;
    margin: 10px; /* Reduced margin for better spacing on mobile */
    min-width: 200px; /* Minimum width for responsiveness */
    padding: 20px; /* Added padding for better spacing */
    text-align: left; /* Align text to the left */
}

.footer-section h2 {
    font-size: 20px;
    border-bottom: 2px solid #ffd105; /* Yellow underline */
    margin-bottom: 15px;
    padding-bottom: 5px;
}

.footer-section p {
    margin-bottom: 15px;
    line-height: 1.5;
    text-align: left; /* Ensure paragraphs are left-aligned */
}

.footer-section ul {
    list-style-type: none;
    padding: 0;
    text-align: left; /* Align list items to the left */
}

.footer-section ul li {
    margin: 5px 0;
}

.footer-section a {
    color: #fff; /* White text for links */
    text-decoration: none;
    transition: color 0.3s;
}

.footer-section a:hover {
    color: #ffd105; /* Yellow on hover */
}

.social-icons {
    display: flex;
    gap: 15px; /* Space between icons */
    margin-top: 10px; /* Space above icons */
}

.social-icons a {
    color: #ffd105; /* Yellow icons */
    font-size: 20px; /* Icon size */
    transition: color 0.3s;
    display: flex; /* Use flex to center icons */
    justify-content: center; /* Center the icon */
    align-items: center; /* Center the icon */
    width: 40px; /* Width of the circular frame */
    height: 40px; /* Height of the circular frame */
    border-radius: 50%; /* Makes the icon circular */
    background-color: rgba(255, 209, 5, 0.2); /* Light yellow background for the circle */
    text-align: center; /* Center text inside the circle */
}

.social-icons a:hover {
    color: #fff; /* Change to white on hover */
    background-color: #ffd105; /* Change background to yellow on hover */
}

.footer-contact {
    line-height: 2;
    text-align: left; /* Align contact info to the left */
}

.footer-contact i {
    color: #ffd105; /* Yellow icons */
    margin-right: 10px;
}

.footer-bottom {
    color: white !important; /* Set footer background to white */
    text-align: center; /* Center the text */
    padding: 20px 0; /* Add padding for spacing */
    position: relative; /* Ensure proper positioning */
    z-index: 10; /* Ensure it stays above other content if needed */
}


.footer-bottom p {
    margin: 5px 0; /* Margin for spacing between lines */
    color: white;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .footer {
        padding: 30px 10px; /* Adjust padding for smaller screens */
    }

    .footer-section {
        margin: 10px 0; /* Vertical spacing between sections */
        text-align: center; /* Center text for better mobile appearance */
    }

    .footer-section h2 {
        font-size: 18px; /* Reduce heading size for mobile */
    }

    .footer-section p {
        font-size: 14px; /* Adjust paragraph font size */
    }

    .footer-bottom {
        font-size: 10px; /* Smaller font size for bottom section */
    }
}

@media (max-width: 480px) {
    .footer-section {
        padding: 15px; /* Less padding for very small screens */
    }

    .footer-section h2 {
        font-size: 16px; /* Further reduce heading size */
    }

    .footer-section p,
    .footer-section ul li {
        font-size: 12px; /* Adjust font size for text */
    }

    .social-icons a {
        width: 35px; /* Slightly smaller circular icons */
        height: 35px; /* Slightly smaller circular icons */
        font-size: 18px; /* Adjust icon font size */
    }
}
