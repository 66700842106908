.disclaimer-container {
    max-width: 800px; /* Set a max width for better readability */
    margin: 20px auto; /* Center the container */
    padding: 30px; /* Add padding for space */
    background: linear-gradient(to right, #f9f9f9, #f1f1f1); /* Gradient background for depth */
    border-radius: 10px; /* Rounded corners for the container */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    color: #5c3c7b; /* Purple text color */
    font-family: 'Arial', sans-serif; /* Use a clean, readable font */
    margin-top: 140px;
}

h1 {
    text-align: center; /* Center the main heading */
    color: #5c3c7b; /* Use brand color for headings */
    margin-bottom: 20px; /* Space below the heading */
}

h2 {
    color: #ffd105; /* Yellow color for subheadings */
    border-bottom: 2px solid #5c3c7b; /* Underline for emphasis */
    padding-bottom: 5px; /* Space below subheading */
}

p {
    line-height: 1.8; /* Improve readability */
    margin: 15px 0; /* Space between paragraphs */
}

a {
    color: #5c3c7b; /* Link color */
    text-decoration: none; /* Remove underline */
}

a:hover {
    text-decoration: underline; /* Underline on hover for clarity */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .disclaimer-container {
        padding: 20px; /* Reduce padding on smaller screens */
        margin: 10px; /* Less margin for smaller screens */
    }

    h1 {
        font-size: 1.8em; /* Adjust font size for the main heading */
    }

    h2 {
        font-size: 1.5em; /* Adjust font size for subheadings */
    }

    p {
        font-size: 1em; /* Ensure body text is readable */
    }
}

@media (max-width: 480px) {
    .disclaimer-container {
        padding: 15px; /* Further reduce padding for very small screens */
    }

    h1 {
        font-size: 1.5em; /* Further adjust main heading font size */
    }

    h2 {
        font-size: 1.3em; /* Further adjust subheading font size */
    }

    p {
        font-size: 0.9em; /* Smaller text for better fit */
    }
}
