/* Property Details Container */
.property-details {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Property Description Section */
.property-description {
    margin-top: 20px;
    background: rgba(255, 209, 5, 0.1); /* Light Yellow background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.property-description h2 {
    font-size: 1.8rem;
    color: #5c3c7b; /* Purple */
    margin-bottom: 10px;
}

.property-description p {
    font-size: 1rem;
    color: #333;
    line-height: 1.6;
}

/* Carousel Styles */
.carousel {
    position: relative;
    max-width: 100%;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 10px;
}

.carousel-images {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.carousel-image {
    min-width: 100%;
    height: auto;
    display: none;
    transition: opacity 0.5s ease-in-out;
}

.carousel-image.active {
    display: block;
    opacity: 1;
}

.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 10;
}

.carousel-button.left {
    left: 10px;
}

.carousel-button.right {
    right: 10px;
}

.carousel-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

/* Property Title */
.property-title {
    font-size: 2.5rem;
    margin: 10px 0;
    color: #5c3c7b; /* Purple */
    text-align: center;
    position: relative;
}

.property-title::after {
    content: '';
    display: block;
    height: 4px;
    width: 50px;
    background: #ffd105; /* Yellow */
    margin: 10px auto;
    border-radius: 2px;
}

/* Marquee Styles */
.marquee {
    background-color: #5c3c7b; /* Purple */
    color: #ffd105;
    padding: 10px;
    border-radius: 5px;
    margin: 20px 0;
    overflow: hidden;
    white-space: nowrap;
}

.marquee-content {
    display: inline-block;
    animation: marquee 15s linear infinite;
}

@keyframes marquee {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
}

/* Highlights Section */
.highlights-section {
    margin-top: 20px;
}

.highlights-section h2 {
    font-size: 1.8rem;
    color: #5c3c7b; /* Purple */
    margin-bottom: 20px;
    border-bottom: 2px solid #ffd105; /* Yellow underline */
    padding-bottom: 5px;
    text-align: center;
}

/* Highlights List as Grid */
.highlights-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

/* Highlight Card Styles */
.highlight-card {
    background: rgba(255, 209, 5, 0.2); /* Light Yellow background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: background 0.3s, transform 0.3s;
}

.highlight-card:hover {
    background: rgba(255, 209, 5, 0.4); /* Darker Yellow on hover */
    transform: translateY(-5px);
}

.highlight-icon {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #5c3c7b; /* Purple */
}

.highlight-card p {
    font-size: 1rem;
    color: #333;
}

/* Property Table */
.table-container {
    margin-top: 40px;
}

.table-container h2 {
    font-size: 1.8rem;
    color: #5c3c7b; /* Purple */
    margin-bottom: 10px;
    border-bottom: 2px solid #ffd105; /* Yellow underline */
    padding-bottom: 5px;
    text-align: center;
}

.property-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffd105; /* Light Yellow background */
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.property-table th,
.property-table td {
    padding: 15px;
    text-align: left;
    color: #333;
    border: 1px solid #ddd;
}

.property-table th {
    background-color: #5c3c7b; /* Purple */
    color: #fff;
    font-weight: bold;
}

.property-table tbody tr:nth-child(odd) {
    background-color: rgba(255, 209, 5, 0.2); /* Light Yellow */
}

.property-table tbody tr:hover {
    background-color: rgba(255, 209, 5, 0.5); /* Darker Yellow on hover */
}

/* Download Brochure Button */
.download-brochure-btn {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #5c3c7b; /* Purple */
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s;
}

.download-brochure-btn a {
    text-decoration: none;
    color: inherit;
}

.download-brochure-btn:hover {
    background-color: #4b2c5f; /* Darker Purple */
}

/* Media Queries */
@media (max-width: 768px) {
    .property-title {
        font-size: 2rem; /* Smaller title font size for mobile */
    }

    .property-description h2 {
        font-size: 1.5rem; /* Adjust description heading size */
    }

    .highlights-list {
        grid-template-columns: 1fr; /* Stack highlight cards on smaller screens */
    }
    
    .highlight-card p {
        font-size: 0.9rem; /* Slightly smaller text for highlight cards */
    }

    .property-table th,
    .property-table td {
        padding: 10px; /* Smaller padding in table cells */
        font-size: 0.9rem; /* Adjust font size for mobile */
    }

    .carousel-button {
        padding: 8px; /* Smaller button size for mobile */
        font-size: 18px; /* Smaller font for buttons */
    }

    .download-brochure-btn {
        padding: 8px 16px; /* Adjust padding for mobile */
        font-size: 0.9rem; /* Smaller font size for button */
    }

    .marquee {
        font-size: 0.9rem; /* Smaller font for marquee on mobile */
        padding: 8px; /* Adjust padding for mobile */
    }
}
