/* Navbar.css */

/* Import a stylish font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background: white; /* Dark background */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    font-family: 'Poppins', sans-serif; /* Modern font */
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999; /* Stay on top */
    transition: transform 0.3s ease; /* Smooth transition for hiding/showing */
}

.navbar.hidden {
    transform: translateY(-100%); /* Hide navbar by moving it up */
}

.navbar.visible {
    transform: translateY(0); /* Show navbar */
}

.navbar-logo img {
    width: 190px;
    max-height: 160px;
    margin-top: -30px;
    margin-bottom: -60px;
    transition: transform 2s ease;
}

.navbar-logo.hovered img {
    transform: scale(1.1); /* Scale up when hovered class is applied */
}

.navbar-links {
    display: flex;
    list-style: none;
    align-items: center;
    padding-right: 60px;
}

.navbar-links li {
    margin-left: 30px; /* More space between links */
    transition: transform 0.3s ease;
}

.navbar-links a {
    text-decoration: none;
    color: #5c3c7b;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: color 0.3s ease;
}

.navbar-links .active-link {
    text-decoration: underline;
    color: black;
}

.navbar-links a:hover {
    color: #a69a2b;
    transform: scale(1.1);
}

.navbar-links li:hover {
    transform: translateY(-3px);
}

.navbar-menu-icon {
    display: none;
    font-size: 24px;
    color: #ffd105;
    cursor: pointer;
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
    .navbar {
        padding: 15px 20px;
        
    }

    .navbar-links {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 80px;
        right: 0;
        background-color: #5c3c7b;
        width: 100%;
        padding: 20px 0;
        text-align: center;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        transition: max-height 0.3s ease;
        max-height: 0;
        overflow: hidden;
    }
    .navbar-links a{
        color: white;
    }

    .navbar-links.active {
        display: flex;
        max-height: 300px;
    }

    .navbar-links li {
        margin: 15px 0;
    }

    .navbar-menu-icon {
        display: block;
        position: absolute;
        right: 20px;
        top: 30px;
        padding-right: 40px;
    }
}
@media (max-width: 448px) {
    .navbar-menu-icon{
        display: block;
        position: absolute;
        right: 50px;
    }
}
