.scroll-to-top {
  position: fixed;
  bottom: 100px;
  right: 25px;
  background-color: #000; /* Button background color */
  border-radius: 50%;
  padding: 12px; /* Slightly increased padding for larger hit area */
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
  z-index: 1000;
  transition: opacity 0.3s, transform 0.3s, background-color 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  width: 30px; /* Fixed width for consistency */
  height: 30px; /* Fixed height for consistency */
}

/* Hover effects */
.scroll-to-top:hover {
  background-color: #ffd105; /* Button hover background color */
  transform: translateY(-3px); /* Hover animation */
  opacity: 1; /* Fully opaque on hover */
}

/* Scroll icon styles */
.scroll-icon {
  color: white; /* Arrow color */
  font-size: 20px;
  transition: color 0.3s ease-in-out;
}

/* Change icon color on hover */
.scroll-to-top:hover .scroll-icon {
  color: black; /* Change arrow color on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .scroll-to-top {
      bottom: 80px; /* Adjust bottom position for smaller screens */
      right: 20px; /* Adjust right position for smaller screens */
      width: 30px; /* Adjust width for smaller screens */
      height: 30px; /* Adjust height for smaller screens */
      padding: 10px; /* Reduce padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .scroll-to-top {
      bottom: 80px; /* Further adjust bottom position */
      right: 15px; /* Further adjust right position */
      width: 20px; /* Adjust width for very small screens */
      height: 20px; /* Adjust height for very small screens */
  }

  .scroll-icon {
      font-size: 18px; /* Slightly smaller icon for very small screens */
  }
}
