/* Enquiry Form Overlay Styles */
.enquiry-form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    animation: fadeIn 0.3s ease-in-out;
}

/* Fade In Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Enquiry Form Container Styles */
.enquiry-form-container {
    background-color: white;
    padding: 30px 40px 30px 30px; /* Increased right padding to 40px */
    border-radius: 12px;
    width: 400px; /* Adjust width as needed */
    max-width: 90%; /* Increased max-width for mobile */
    position: relative;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.4);
    animation: slideUp 0.4s ease-in-out;
}


/* Slide Up Animation */
@keyframes slideUp {
    from {
        transform: translateY(50px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Form Title Styles */
.enquiry-form-container h2 {
    margin-bottom: 15px; /* Reduced margin */
    color: #5c3c7b;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
}

/* Label Styles */
.enquiry-form-container label {
    display: block;
    margin-bottom: 8px; /* Reduced margin */
    color: #333;
    font-weight: 600;
    font-size: 14px;
}

/* Input and Select Styles */
.enquiry-form-container input,
.enquiry-form-container select {
    width: 100%;
    padding: 10px; /* Reduced padding */
    margin-bottom: 15px; /* Reduced margin */
    border: 2px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Focus State Styles */
.enquiry-form-container input:focus,
.enquiry-form-container select:focus {
    border-color: #5c3c7b;
    box-shadow: 0 0 5px rgba(92, 60, 123, 0.5);
}

/* Button Styles */
.enquiry-form-container button {
    background-color: #ffd105;
    color: black;
    border: none;
    padding: 12px; /* Reduced padding */
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 8px;
    width: 100%;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-transform: uppercase;
}

/* Button Hover Effect */
.enquiry-form-container button:hover {
    background-color: #e6b800;
    transform: translateY(-2px);
}

/* Close Button Styles */
.close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    border-radius: 50%;
    max-width: 10px; /* Adjusted for better touch target */
    height: 30px; /* Adjusted for better touch target */
    color: #5c3c7b;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease, color 0.3s ease;
}

/* Close Button Hover Effect */
.close-btn:hover {
    background: rgba(92, 60, 123, 0.2);
    transform: scale(1.1);
    color: #f4cf29;
}

/* Responsive Styles for Mobile Devices */
@media (max-width: 600px) {
    .enquiry-form-container {
        width: 90%;
        padding: 15px; /* Further reduced padding for mobile */
    }

    .enquiry-form-container h2 {
        font-size: 22px; /* Reduced font size for mobile */
    }

    .enquiry-form-container button {
        font-size: 16px; /* Reduced button font size */
        padding: 10px; /* Reduced button padding */
    }

    .close-btn {
        width: 30px; /* Consistent size for touch targets */
        height: 30px; /* Consistent size for touch targets */
        font-size: 18px; /* Adjusted font size */
    }
}

/* Accessibility Improvements */
.enquiry-form-container input:focus,
.enquiry-form-container select:focus,
.enquiry-form-container button:focus,
.close-btn:focus {
    outline: 3px solid #ffd105; /* Outline for accessibility when focused */
}
