/* Main Slider Container */
.hero-slider {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    background-color: #5c3c7b;
    height: 90vh;
    font-family: 'Poppins', sans-serif;
}

.slider-container {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.slide {
    min-width: 100%;
    transition: opacity 1s ease-in-out;
    display: flex;
    justify-content: center;
}

.slide-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 90vh;
}

.slide.active {
    display: block;
    opacity: 1;
}

.slide:not(.active) {
    display: none;
    opacity: 0;
}

/* Thumbnail Carousel */
.slider-thumbnails {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
}

.thumbnail {
    margin: 0 5px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.thumbnail-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border: 2px solid transparent;
    transition: border 0.3s;
    border-radius: 5px;
}

.thumbnail.active .thumbnail-image {
    border-color: #ffd105;
}

.thumbnail:hover {
    transform: scale(1.1);
}

/* Real Estate Section Styling */
.real-estate-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px;
    background-color: #ffd105;
    color: white;
    font-family: 'Poppins', sans-serif;
}

.image-stack {
    position: relative;
    width: 60%;
    margin-left: 90px;
    margin-top: 60px;
}

.image-stack img {
    position: absolute;
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
}

.image1 { top: 60px; left: -50px; z-index: 1; }
.image2 { top: 20px; left: 70px; z-index: 2; }
.image3 { top: -20px; left: 200px; z-index: 3; }
.image4 { top: 40px; left: 400px; z-index: 4; }

.text-section {
    width: 50%;
    padding-left: 20px;
}

.text-section h1 {
    font-size: 2.8rem;
    margin-bottom: 20px;
    font-weight: 800;
    color: #5c3c7b;
    font-size: 35px;
}

.text-section p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: black;
    text-align: justify;
}

/* Media Queries for mobile responsiveness */
@media (max-width: 768px) {
    .hero-slider{
        height: 46vh;
    }
    .real-estate-section {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .image-stack {
        display: grid;
        grid-template-columns: 1fr 1fr; /* Two-column grid for images */
        gap: 10px;
        width: 100%;
        margin: 0 auto 20px;
        position: static;
    }

    .image-stack img {
        position: static;
        width: 100%;
        height: 25vh;
        margin: 0;
    }

    .text-section {
        width: 100%;
        text-align: center;
    }

    .text-section h1 {
        font-size: 1.8rem;
        margin-bottom: 10px;
    }

    .text-section p {
        font-size: 1rem;
        line-height: 1.6;
        padding-right: 10px;
    }
}

@media (max-width: 480px) {
    .hero-slider {
        height: 43vh;
    }
    
    .text-section h1 {
        font-size: 1.5rem;
    }

    .text-section p {
        font-size: 0.9rem;
    }

    .thumbnail-image {
        width: 60px;
    }

    .slider-thumbnails {
        bottom: 5px;
    }
    .slide-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 110vh;
    }
}
