/* Partnerships Section Styles */
.pagination-dots {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination-dots .dot {
    width: 12px;
    height: 12px;
    margin: 0 5px;
    background-color: #ddd;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination-dots .dot.active {
    background-color: #5c3c7b; /* Purple color for the active dot */
}

.partnerships {
    background-color: #ffd105; /* White background */
    text-align: center; /* Center align text */
    margin-top: 0px;
}

.partnerships .content {
    max-width: 1200px; /* Maximum width for the content */
    margin: 0 auto; /* Center the content */
}

.partnerships h2 {
    font-size: 2.5rem; /* Font size for the heading */
    color: #5c3c7b; /* Purple color for heading */
    margin-bottom: 20px; /* Space below heading */
}

.partnerships p {
    font-size: 1.2rem; /* Font size for paragraph */
    color: #1a1a1a; /* Grey color for text */
    margin-bottom: 40px; /* Space below paragraph */
}

.partnership-cards {
    display: flex; /* Flexbox for alignment */
    justify-content: center; /* Center align items */
    flex-wrap: wrap; /* Allow wrapping so logos can display responsively */
    gap: 20px; /* Space between items */
    
}

.partnership-card {
    background-color: #ffffff;
    display: flex; /* Flex for card content */
    flex-direction: column; /* Vertical layout for logo and name */
    align-items: center; /* Center align items */
    border-radius: 10px; /* Rounded corners */
    padding: 20px; /* Padding inside the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for cards */
    transition: transform 0.3s; /* Smooth transform effect */
    width: 200px; /* Fixed width for each card */
}

.partnership-card img {
    width: 150px; /* Fixed width for logos */
    height: 150px; /* Set height to ensure all logos are the same height */
    object-fit: contain; /* Maintain aspect ratio within the set height */
    margin-bottom: 10px; /* Space below the logo */
    margin-top: 20px;
}

.partnership-card:hover {
    transform: translateY(-5px); /* Lift effect on hover */
}

.pagination-controls {
    display: flex;                   /* Use flexbox for layout */
    justify-content: center;         /* Center the controls */
    align-items: center;             /* Align items vertically */
    margin-top: 20px;               /* Space above pagination controls */
}

.pagination-controls button {
    background-color: #f0f0f0;   /* Transparent background for buttons */
    border: none;                    /* Remove default border */
    cursor: pointer;                 /* Change cursor to pointer on hover */
    padding: 10px;                   /* Add padding for better click area */
    margin: 0 15px;                  /* Space between buttons */
    font-size: 18px;                 /* Font size for buttons */
    transition: background-color 0.3s; /* Smooth background color transition */
    color: #5c3c7b;
}

.pagination-controls button:hover {
    background-color: yellow;       /* Change background on hover */
}

.pagination-controls button:disabled {
    cursor: not-allowed;             /* Change cursor when disabled */
    opacity: 0.5;                    /* Make disabled buttons semi-transparent */
}

/* Responsive Design */
@media (max-width: 1024px) {
    .partnership-cards {
        justify-content: space-around; /* More space between items for medium screens */
    }

    .partnership-card {
        width: 180px; /* Adjust width for smaller screens */
    }

    .partnership-card img {
        width: 130px; /* Adjust logo size for smaller screens */
        height: 130px;
    }
}

@media (max-width: 768px) {
    .partnership-cards {
        justify-content: center;
        gap: 15px; /* Reduce gap between cards */
    }

    .partnership-card {
        width: 160px; /* Narrower cards on smaller screens */
    }

    .partnership-card img {
        width: 120px; /* Adjust logo size for mobile screens */
        height: 120px;
    }
    .partnerships {
        margin-top: -130px;
    }
}

@media (max-width: 480px) {
    .partnership-cards {
        display: grid; /* Change to grid layout */
        grid-template-columns: 1fr; /* One card per row */
        gap: 10px; /* Space between cards */
        max-height: 380px; /* Limit height to show only two cards */
        overflow: hidden; /* Hide additional cards */
    }

    .partnership-card {
        width: auto; /* Adjust width to fit grid */
    }

    .partnership-card img {
        width: 100px; /* Smaller logo size for mobile */
        height: 100px;
    }
}
