/* General Styles */
.commercial-page {
  font-family: 'Poppins', sans-serif; /* Consistent font throughout */
}

/* Hero Section */
.hero-section h1 {
  font-size: 2.5rem; /* Smaller title for mobile */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Shadow for depth */
  margin: 0 10px; /* Margin for better spacing on small screens */
  color: white;
}

/* Breadcrumbs */
.breadcrumbs {
  padding: 10px; /* Reduced padding for mobile */
  background-color: #f4f4f4; /* Light background for contrast */
  font-size: 12px; /* Smaller font size */
  margin: 15px 0; /* Reduced margin */
}

.breadcrumbs a {
  text-decoration: none; /* Remove underline */
  color: #5c3c7b; /* Consistent color */
}

/* Marquee */
.marquee {
  background-color: #ffd105; /* Bright background for attention */
  color: #5c3c7b; /* Consistent text color */
  padding: 8px; /* Reduced padding for mobile */
  font-weight: bold; /* Bold text */
  margin-bottom: 30px; /* Reduced space below */
}

/* Filter Section */
.filter-section {
  text-align: center; /* Center text */
  padding: 30px 10px; /* Reduced padding */
  background-color: #f4f4f4; /* Consistent background */
}

.filter-section h2 {
  font-size: 1.8rem; /* Smaller font size for title */
  color: #5c3c7b; /* Consistent color */
  margin-bottom: 15px; /* Reduced space below */
}

/* Filter Inputs */
.filter-inputs {
  display: flex; /* Flexbox for alignment */
  flex-direction: column; /* Stack inputs vertically on mobile */
  align-items: center; /* Center align inputs */
  gap: 10px; /* Space between inputs */
}

.filter-inputs input,
.filter-inputs select,
.filter-inputs button {
  padding: 10px; /* Consistent padding */
  font-size: 14px; /* Smaller font size for readability */
  border-radius: 5px; /* Rounded corners */
  border: 1px solid #ccc; /* Light border */
  width: 90%; /* Full width for inputs */
}

/* Button Styles */
.filter-inputs button {
  background-color: #5c3c7b; /* Consistent button color */
  color: #fff; /* White text */
  border: none; /* Remove border */
  cursor: pointer; /* Pointer on hover */
}

.filter-inputs button:hover {
  background-color: #4a2e5a; /* Darker shade on hover */
}

/* Property List */
.property-list {
  display: flex; /* Flexbox for alignment */
  flex-wrap: wrap; /* Allow wrapping */
  padding: 20px; /* Reduced spacing */
}

/* Property Card Styles */
.property-card {
  max-width: 400px;
  background-color: white; /* White background for cards */
  padding: 15px; /* Padding inside */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  text-align: center; /* Center text */
  margin: 10px; /* Space around cards */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

/* Ensure image is responsive */
.property-card img {
  width: 100%; /* Responsive image */
  height: 150px; /* Fixed height for consistency */
  object-fit: cover; /* Crop to cover */
  border-radius: 10px; /* Rounded corners */
  margin-bottom: 10px; /* Space below image */
}

.property-card h3 {
  color: #5c3c7b; /* Consistent color */
  font-size: 1.3rem; /* Smaller font size for titles */
}

/* Property Description */
.property-card p {
  color: #666; /* Grey text for description */
  margin-bottom: 15px; /* Space below */
}

/* View Details Button */
.view-details {
  display: inline-block; /* Display as inline block */
  background-color: #ffd105; /* Bright background */
  padding: 8px 15px; /* Reduced padding */
  color: #fff; /* White text */
  text-decoration: none; /* Remove underline */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transition */
}

.view-details:hover {
  background-color: #5c3c7b; /* Darker shade on hover */
}

/* Footer Styles */
.footer {
  background-color: #333; /* Dark background */
  color: #fff; /* White text */
  text-align: center; /* Center align text */
  padding: 15px; /* Reduced padding */
}

/* Responsive Design */
@media (max-width: 768px) {
  .property-card {
    width: calc(100% - 20px); /* One card per row on small screens */
  }
}

@media (min-width: 769px) {
  .property-card {
    width: calc(33.33% - 20px); /* Three cards per row on larger screens */
  }
}
