/* Blog Section Styles */
.blog-section {
    padding: 60px 20px; /* Increased padding for a more spacious feel */
    background: linear-gradient(to right, #f4f4f4, #eaeaea); /* Subtle gradient background */
    margin-top: -90px;
}

.blog-container {
    max-width: 1200px;
    margin: 0 auto; /* Center the container */
    text-align: center;
}

.blog-section h2 {
    font-size: 3rem; /* Increased font size for better emphasis */
    margin-bottom: 40px;
    color: #5c3c7b; /* Purple text for title */
    font-family: 'Lato', sans-serif; /* Font family */
    font-weight: 700; /* Bold title */
    text-transform: uppercase; /* Uppercase for emphasis */
    letter-spacing: 1px; /* Slight letter spacing for elegance */
}

.blog-cards {
    display: flex;
    justify-content: space-between; /* Even space between cards */
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.blog-card {
    background: white;
    border-radius: 12px; /* Slightly more rounded corners */
    width: calc(28% - 20px); /* 3 cards per row with spacing */
    margin: 10px; /* Space between cards */
    padding: 30px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1); /* Softer shadow effect */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
    position: relative; /* For pseudo-elements */
}

.blog-card:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 209, 5, 0.1); /* Light yellow overlay */
    border-radius: 12px; /* Match the card's border radius */
    z-index: 0; /* Behind the content */
}

.blog-card:hover {
    transform: translateY(-8px); /* Lift effect on hover */
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

.blog-card img {
    width: 100%; /* Make the image responsive */
    border-radius: 12px; /* Rounded corners for the image */
    height: 200px; /* Fixed height for uniformity */
    object-fit: cover; /* Crop image to fit */
    margin-bottom: 0px; /* Space between image and title */
    border: 2px solid #ffd105; /* Yellow border for images */
}

.blog-card h3 {
    font-size: 1.8rem; /* Increased font size for titles */
    margin: 10px 0;
    color: #5c3c7b; /* Purple color for titles */
    font-weight: 600; /* Semi-bold titles */
    font-family: 'Lato', sans-serif; /* Font family */
}

.blog-card p {
    font-size: 16px; /* Increased font size for descriptions */
    margin-bottom: 20px; /* Space below description */
    color: #666; /* Grey text for description */
    line-height: 1.6; /* Improved line height for readability */
    font-family: 'Lato', sans-serif; /* Font family */
}

/* Why Choose Us Section Styles */
.why-choose-us {
    padding: 30px 0; /* Padding for top and bottom */
    background-color: #ffd105; /* Yellow background */
    overflow: hidden; /* Prevent overflow beyond the screen */
}

.why-choose-us .content {
    max-width: 1200px; /* Match the max width of the testimonial section */
    margin: 0 auto; /* Center the content */
    padding: 0 20px; /* Add horizontal padding for smaller screens */
}

.why-choose-us h2 {
    font-size: 2.5rem; /* Slightly smaller than the main heading */
    color: #5c3c7b; /* Purple title */
    font-weight: 700; /* Bold title */
    margin-bottom: 20px; /* Space below heading */
    text-align: center;
}

.why-cards {
    display: flex;
    justify-content: center; /* Center align the cards */
    flex-wrap: nowrap; /* Keep cards in a single row */
    overflow-x: auto; /* Allow horizontal scrolling on smaller devices */
}

.why-card {
    background-color: #fff; /* White background for cards */
    border-radius: 10px; /* Rounded corners for cards */
    padding: 20px; /* Padding inside cards */
    margin: 0 10px; /* Space between cards */
    text-align: center; /* Center align text */
    flex: 0 0 220px; /* Flex basis for card size */
    transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition for hover effect */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15); /* Box shadow for depth */
    margin-bottom: 15px;
}

.why-card:hover {
    transform: translateY(-5px); /* Lift effect on hover */
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

.icon {
    font-size: 3rem; /* Large icon size */
    margin-bottom: 10px; /* Space below icon */
}

.why-card h3 {
    font-size: 1.5rem; /* Title size in cards */
    margin-bottom: 10px; /* Space below title */
}

.why-card p {
    font-size: 1rem; /* Description size in cards */
}


/* Partnerships Section Styles */
.pagination-dots {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination-dots .dot {
    width: 12px;
    height: 12px;
    margin: 0 5px;
    background-color: #ddd;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination-dots .dot.active {
    background-color: #5c3c7b; /* Purple color for the active dot */
}

.partnerships {
    padding: 60px 20px; /* Add padding for spacing */
    background-color: white; /* White background */
    text-align: center; /* Center align text */
}

.partnerships .content {
    max-width: 1200px; /* Maximum width for the content */
    margin: 0 auto; /* Center the content */
    margin-top: 40px;
    padding-top: 50px;
}

.partnerships h2 {
    font-size: 2.5rem; /* Font size for the heading */
    color: #5c3c7b; /* Purple color for heading */
    margin-bottom: 20px; /* Space below heading */
}

.partnerships p {
    font-size: 1.2rem; /* Font size for paragraph */
    color: #1a1a1a; /* Grey color for text */
    margin-bottom: 40px; /* Space below paragraph */
}

.partnership-cards {
    display: flex; /* Flexbox for alignment */
    justify-content: center; /* Center align items */
    flex-wrap: wrap; /* Allow wrapping so logos can display responsively */
    gap: 20px; /* Space between items */
}

.partnership-card {
    display: flex; /* Flex for card content */
    flex-direction: column; /* Vertical layout for logo and name */
    align-items: center; /* Center align items */
    border-radius: 10px; /* Rounded corners */
    padding: 20px; /* Padding inside the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for cards */
    transition: transform 0.3s; /* Smooth transform effect */
    width: 200px; /* Fixed width for each card */
}

.partnership-card img {
    width: 150px; /* Fixed width for logos */
    height: 150px; /* Set height to ensure all logos are the same height */
    object-fit: contain; /* Maintain aspect ratio within the set height */
    margin-bottom: 10px; /* Space below the logo */
    margin-top: 20px;
}

.partnership-card:hover {
    transform: translateY(-5px); /* Lift effect on hover */
}
.pagination-controls {
    display: flex;                   /* Use flexbox for layout */
    justify-content: center;         /* Center the controls */
    align-items: center;             /* Align items vertically */
    margin-top: 20px;               /* Space above pagination controls */
}

.pagination-controls button {
    background-color: #f0f0f0;   /* Transparent background for buttons */
    border: none;                    /* Remove default border */
    cursor: pointer;                 /* Change cursor to pointer on hover */
    padding: 10px;                   /* Add padding for better click area */
    margin: 0 15px;                  /* Space between buttons */
    font-size: 18px;                 /* Font size for buttons */
    transition: background-color 0.3s; /* Smooth background color transition */
    color: #5c3c7b;
}

.pagination-controls button:hover {
    background-color: yellow;       /* Change background on hover */
}

.pagination-controls button:disabled {
    cursor: not-allowed;             /* Change cursor when disabled */
    opacity: 0.5;                    /* Make disabled buttons semi-transparent */
}




/* Responsive Design */
@media (max-width: 1024px) {
    .partnership-cards {
        justify-content: space-around; /* More space between items for medium screens */
    }

    .partnership-card {
        width: 180px; /* Adjust width for smaller screens */
    }

    .partnership-card img {
        width: 130px; /* Adjust logo size for smaller screens */
        height: 130px;
    }
}

@media (max-width: 768px) {
    .partnership-cards {
        justify-content: center;
        gap: 15px; /* Reduce gap between cards */
    }

    .partnership-card {
        width: 160px; /* Narrower cards on smaller screens */
    }

    .partnership-card img {
        width: 120px; /* Adjust logo size for mobile screens */
        height: 120px;
    }
    .blog-container h2{
        font-size: 35px;
    }
    .blog-card{
        max-width: 140px;
        padding: 15px;
        justify-content: center;

    }
    .blog-cards{
        margin-left: 40px;
        margin-right: 40px;


        }
    .blog-cards img{
        
        object-fit: contain;
border: none;  
padding: -30px;  
}

    
}

@media (max-width: 480px) {
    .partnership-cards {
        flex-direction: column; /* Stack cards vertically on small mobile screens */
        gap: 10px; /* Reduce gap between stacked cards */
    }

    .partnership-card {
        width: 100%; /* Full width cards for mobile */
    }

    .partnership-card img {
        width: 100px; /* Smaller logo size for mobile */
        height: 100px;
    }
}

/* Responsive Design */
@media (max-width: 1200px) {
    .blog-card {
        width: calc(30% - 20px); /* 3 cards per row on medium screens */
    }

    .why-card {
        width: 200px; /* Reduce card width on medium screens */
    }
}

@media (max-width: 992px) {
    .blog-card {
        width: calc(45% - 20px); /* 2 cards per row on smaller screens */
    }

    .why-card {
        width: 180px; /* Further reduce card width on smaller screens */
    }
}

@media (max-width: 768px) {
    .blog-card {
        width: 100%; /* Stack cards on smaller screens */
        margin: 10px 0; /* Margin between stacked cards */
    }

    .why-cards {
        flex-wrap: wrap; /* Allow wrapping for small devices */
    }

    .why-card {
        width: 100%; /* Full width for cards on small screens */
    }
}

@media (max-width: 480px) {
    .blog-section h2, .why-choose-us h2, .partnerships h2 {
        font-size: 2rem; /* Smaller font size for headings */
    }

    .blog-card h3, .why-card h3, .partnership-card h3 {
        font-size: 1.5rem; /* Smaller font size for titles */
    }

    .blog-card p, .why-card p, .partnership-card p {
        font-size: 0.9rem; /* Smaller font size for descriptions */
    }
}
/* Responsive Design for Mobile View - 480px and below */
@media (max-width: 480px) {
    .blog-cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two cards per row */
        gap: 20px; /* Space between cards */
        margin: 0 10px; /* Add some margin to center the cards */
        margin-right: 10px;
        margin-left: -10px;
    }

    .blog-card {
        width: 100%; /* Full width within the grid */
        padding: 10px; /* Reduce padding of each card */
    }

    .blog-card img {
        padding: 5px; 
        width: 100%; 
        height: 100px; 
        object-fit: cover;
    }
}
