.about-us-section {
  background: #ffffff;
  padding: 50px 0;
  color: #fff;
  text-align: center;
}

.about-us-hero {
  background-image: url('../assets/img1.jpg'); /* Adjust the path */
  background-size: cover;
  background-position: center;
  position: relative;
  height: 400px; /* Default height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-overlay {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
}

.hero-title {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
}

.hero-subtitle {
  font-size: 1.5rem;
  font-style: italic;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); /* Two columns */
  gap: 20px;
}

.glassmorphic-card {
  background-color: #ffd105;
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 30px;
  margin: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.glassmorphic-card h2 {
  font-size: 2rem;
  color: #5c3c7b;
  margin-bottom: 15px;
}

.glassmorphic-card p {
  color: #fff;
  font-size: 1.1rem;
  line-height: 1.8;
  text-align: justify;
}

.glassmorphic-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.hero-title:hover,
.hero-subtitle:hover {
  color: #ffd105;
}

.atul-chordia-section {
  display: flex;
  align-items: center;
  padding: 50px 20px; /* Added padding for smaller screens */
  background-color: #ffd105; /* Apply glassmorphic effect */
  backdrop-filter: blur(10px);
  border-radius: 20px;
  margin: 20px;
  color: white;
}

.atul-chordia-section .image-container {
  flex: 1;
  text-align: center;
}

.atul-chordia-section .image-container img {
  max-width: 90%;
  border-radius: 10px;
}

.atul-chordia-section .text-container {
  flex: 1;
  padding: 20px;
}

.atul-chordia-section h2 {
  font-size: 50px;
  margin-bottom: 20px;
  color: #5c3c7b;
  text-align: left;
}

.text-container p {
  font-size: 10px;
  color: black;
  text-align: justify;
}

.atul-chordia-section p {
  font-size: 1.2rem;
  line-height: 1.6;
}

/* Responsive behavior */
@media (max-width: 1200px) {
  .about-us-hero {
    height: 350px; /* Slightly reduced height for medium screens */
  }

  .hero-title {
    font-size: 3.5rem; /* Reduced title size */
  }

  .hero-subtitle {
    font-size: 1.3rem; /* Reduced subtitle size */
  }
}

@media (max-width: 992px) {
  .about-us-hero {
    height: 300px; /* Further reduce height for smaller screens */
  }

  .hero-title {
    font-size: 3rem; /* Reduced title size */
  }

  .hero-subtitle {
    font-size: 1.2rem; /* Reduced subtitle size */
  }

  .container {
    padding: 0 10px; /* Added padding for better spacing */
  }
}

@media (max-width: 768px) {
  .about-us-hero {
    height: 250px; /* Reduced height for mobile devices */
  }

  .hero-title {
    font-size: 2.5rem; /* Further reduced title size */
  }

  .hero-subtitle {
    font-size: 1rem; /* Further reduced subtitle size */
  }

  .atul-chordia-section {
    flex-direction: column; /* Stacks text and image vertically */
    padding: 30px 10px; /* Adjust padding for smaller screens */
  }

  .atul-chordia-section .text-container,
  .atul-chordia-section .image-container {
    width: 100%; /* Full width for smaller screens */
  }

  .atul-chordia-section h2 {
    font-size: 2rem; /* Reduced heading size */
  }

  .atul-chordia-section p {
    font-size: 1rem; /* Reduced paragraph size */
  }

  .container {
    grid-template-columns: 1fr; /* Stacks cards in a single column on smaller screens */
  }

  .glassmorphic-card {
    margin: 10px; /* Reduced margin for smaller screens */
    padding: 20px; /* Reduced padding for smaller screens */
  }

  .glassmorphic-card h2 {
    font-size: 1.5rem; /* Reduced heading size */
  }

  .glassmorphic-card p {
    font-size: 0.9rem; /* Reduced paragraph size */
  }
}

@media (max-width: 480px) {
  .about-us-section {
    padding: 30px 0; /* Reduced padding for mobile screens */
    padding-top: 60px;
  }

  .hero-title {
    font-size: 2rem; /* Further reduced title size */
  }

  .hero-subtitle {
    font-size: 0.9rem; /* Further reduced subtitle size */
  }

  .glassmorphic-card {
    margin: 5px; /* Minimized margin for tighter spacing */
    padding: 15px; /* Minimized padding for tighter spacing */
  }

  .glassmorphic-card h2 {
    font-size: 1.3rem; /* Further reduced heading size */
  }

  .glassmorphic-card p {
    font-size: 0.8rem; /* Further reduced paragraph size */
  }
}

.founders-section {
  padding: 50px 20px;
  background-color: white;
  text-align: center;
}

.founders-section h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #5c3c7b;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}

.founders-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.founder-card {
  display: flex;
  flex-basis: 100%;
  max-width: 1000px;
  background-color: #5c3c7b;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.founder-image {
  flex: 0 0 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.founder-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.founder-text {
  flex: 0 0 90%;
  padding: 20px;
  color: #333;
  text-align: left;
  font-family: 'Lato', sans-serif;
}



.founder-text p {
  font-size: 1rem;
  margin: 0;
  line-height: 1.6;
  color: #ffd105;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .founder-card {
    flex-direction: column; /* Stack the card elements vertically */
    text-align: center; /* Center align text for mobile view */
  }

  .founder-image {
    flex: 1 1 100%; /* Make the image take full width */
    margin-bottom: 15px; /* Space below image */
  }

  .founder-image img {
    width: 80%; /* Adjust image width for better appearance */
  }

  .founder-text {
    flex: 1 1 100%; /* Make text container full width */
    padding: 15px; /* Reduced padding for mobile view */
  }

  .founders-section h2 {
    font-size: 2rem; /* Reduced font size for the heading */
    margin-bottom: 30px; /* Adjust margin */
  }

  .founder-text h3 {
    font-size: 1.5rem; /* Adjust heading size */
  }

  .founder-text p {
    font-size: 0.9rem; /* Adjust paragraph size */
  }
}

@media (max-width: 480px) {
  .founders-section h2 {
    font-size: 1.5rem; /* Further reduce heading size */
  }

  .founder-card {
    margin: 10px 0; /* Reduced margin for mobile cards */
  }

  .founder-text h3 {
    font-size: 1.2rem; /* Further adjust heading size */
  }
  .founder-image img {
    height: 150px; /* Set a fixed height for smaller screens */
    object-fit: contain; /* Maintain aspect ratio */
    max-width: 40px;
  }

  .founder-text p {
    font-size: 0.8rem; /* Further adjust paragraph size */
    text-align: center;
  }
}
