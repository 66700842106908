/* Styling for the Fifth Section */
.fifth-section {
    padding: 40px 20px 50px 20px; /* Reduced horizontal padding for mobile */
    text-align: center;
    background-color: #fff;
}

.title h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #5c3c7b;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
}

/* Image Grid Styles */
.image-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 columns in each row */
    grid-template-rows: repeat(2, auto); /* 2 rows of images */
    column-gap: 10px; /* Space between columns */
    row-gap: 0px; /* Set row-gap to 0 */
}

/* Individual Grid Item Styles */
.grid-item {
    overflow: hidden;
}

.grid-item img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Margin Adjustments for Specific Grid Items */
.image-grid .grid-item:nth-child(1),
.image-grid .grid-item:nth-child(2),
.image-grid .grid-item:nth-child(3),
.image-grid .grid-item:nth-child(4),
.image-grid .grid-item:nth-child(5) {
    margin-bottom: -80px; /* No margin on first row images */
}

.image-grid .grid-item:nth-child(2),
.image-grid .grid-item:nth-child(4),
.image-grid .grid-item:nth-child(7),
.image-grid .grid-item:nth-child(9) {
    margin-top: 60px; /* Adjust as needed */
}

.image-grid .grid-item:nth-child(3),
.image-grid .grid-item:nth-child(8) {
    margin-top: 90px; /* Increased margin for 3rd column */
}

/* Responsive Styles for Medium Screens */
@media (max-width: 1024px) {
    .image-grid {
        grid-template-columns: repeat(4, 1fr); /* 4 columns for medium screens */
    }

    /* Re-adjust the margin-top for 2nd, 3rd, and 4th columns in the new layout */
    .image-grid .grid-item:nth-child(2),
    .image-grid .grid-item:nth-child(4),
    .image-grid .grid-item:nth-child(6),
    .image-grid .grid-item:nth-child(8) {
        margin-top: 20px;
    }

    .image-grid .grid-item:nth-child(3),
    .image-grid .grid-item:nth-child(7) {
        margin-top: 40px;
    }
}

/* Responsive Styles for Tablet Screens */
@media (max-width: 768px) {
    .image-grid {
        grid-template-columns: repeat(3, 1fr); /* 3 columns for tablet */
        column-gap: 10px;
        row-gap: 15px;
    }

    .title h1 {
        font-size: 2rem;
        margin-bottom: 18px;
    }

    /* Staggering Effect to Preserve Visual Flow */
    .image-grid .grid-item:nth-child(2),
    .image-grid .grid-item:nth-child(5),
    .image-grid .grid-item:nth-child(8) {
        margin-top: 20px;
    }

    .image-grid .grid-item:nth-child(1),
    .image-grid .grid-item:nth-child(2),
    .image-grid .grid-item:nth-child(3) {
        margin-bottom: 1px;
    }
    .image-grid .grid-item:nth-child(4)
    {
        margin-top: 3px;
    }
    
    .image-grid .grid-item:nth-child(5)
    {
        margin-top: -15px;
    }
    .image-grid .grid-item:nth-child(6)
    {
        margin-top: 5px;
    }
    .image-grid .grid-item:nth-child(7)
    {
        margin-top: -5px;
    }
    .image-grid .grid-item:nth-child(8)
    {
        margin-top: -30px;
    }
    .image-grid .grid-item:nth-child(9)
    {
        margin-top: 0px;
    }
    .image-grid .grid-item:nth-child(10)
    {display: none;
        
    }
    .image-grid .grid-item:nth-child(3),
    .image-grid .grid-item:nth-child(1) {
        margin-top: 40px;
    }

}

/* Responsive Styles for Mobile Devices (480px and below) */
/* Responsive Styles for Mobile Devices (480px and below) */
@media (max-width: 480px) {
    .image-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 columns for mobile */
        column-gap: 8px; /* Adjust column spacing for mobile */
        row-gap: 10px; /* Adjust row spacing for mobile */
    }

    .title h1 {
        font-size: 1.8rem;
        margin-bottom: 15px;
    }

    /* Staggering Effect to Preserve Visual Flow */
    .image-grid .grid-item:nth-child(1) {
        margin-top: 10px;
    }

    .image-grid .grid-item:nth-child(2) {
        margin-top: 20px;
    }

    .image-grid .grid-item:nth-child(3) {
        margin-top: 0;
    }

    .image-grid .grid-item:nth-child(4) {
        margin-top: 15px;
    }

    .image-grid .grid-item:nth-child(5) {
        margin-top: -10px;
    }

    .image-grid .grid-item:nth-child(6) {
        margin-top: 5px;
    }

    .image-grid .grid-item:nth-child(7) {
        margin-top: -5px;
    }

    .image-grid .grid-item:nth-child(8) {
        margin-top: -20px;
    }

    .image-grid .grid-item:nth-child(9) {
        margin-top: 10px;
    }

    /* Hide the 10th image */
    .image-grid .grid-item:nth-child(10) {
        display: none;
    }
}
