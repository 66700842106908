.contact-us-section {
  background: white;
  padding: 50px 0;
  color: #fff;
  text-align: center;
}

.social-icons {
  display: flex;
  gap: 15px; /* Space between icons */
  margin-top: 1px; /* Space above icons */
}

.social-icons a {
  color: #ffd105; /* Yellow icons */
  font-size: 20px; /* Icon size */
  transition: color 0.3s;
  display: flex; /* Use flex to center icons */
  justify-content: center; /* Center the icon */
  align-items: center; /* Center the icon */
  width: 40px; /* Width of the circular frame */
  height: 40px; /* Height of the circular frame */
  border-radius: 50%; /* Makes the icon circular */
  background-color: #5c3c7b; /* Light yellow background for the circle */
  text-align: center; /* Center text inside the circle */
}

.social-icons a:hover {
  color: #fff; /* Change to white on hover */
  background-color: #ffd105; /* Change background to yellow on hover */
}

/* Hero Section */
.contact-us-hero {
  background-image: url('../assets/img1.jpg'); /* Background image */
  background-size: cover; /* Cover the entire area */
  background-position: center; /* Center the image */
  position: relative; /* Position for overlay */
  height: 400px; /* Fixed height for desktop */
  display: flex; /* Flexbox for centering */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  margin-bottom: 50px;
}

.hero-overlay {
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  padding: 20px; /* Padding for overlay */
  border-radius: 10px; /* Rounded corners */
}

.hero-title {
  font-size: 4rem; /* Large title */
  font-weight: bold; /* Bold text */
  margin-bottom: 10px; /* Space below */
}

.hero-subtitle {
  font-size: 1.5rem; /* Subtitle size */
  font-style: italic; /* Italic text */
}

/* Container for Cards */
.container {
  max-width: 1200px; /* Maximum width for large screens */
  margin: 0 auto; /* Center container */
  padding: 0 20px; /* Side padding */
  display: grid; /* Grid layout */
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); /* Responsive columns */
  gap: 90px; /* Increased space between cards */
}

/* Glassmorphic Card Styles */
.glassmorphic-card {
  background: rgba(255, 255, 255, 0.1); /* Glass effect */
  backdrop-filter: blur(10px); /* Blur effect */
  border-radius: 20px; /* Rounded corners */
  padding: 30px; /* Inner padding */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animation on hover */
  margin: 0; /* Reset margins to allow gap to work */
}

.glassmorphic-card h2 {
  font-size: 2rem; /* Title size */
  color: #5c3c7b; /* Title color */
  margin-bottom: 15px; /* Space below */
}

.glassmorphic-card p {
  color: #000; /* Black text for description */
  font-size: 1.1rem; /* Description size */
  line-height: 1.8; /* Line height */
  text-align: left; /* Left align text */
  text-align: justify;
}

.glassmorphic-card:hover {
  transform: translateY(-10px); /* Lift effect on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); /* Shadow on hover */
}

/* Contact Form Styles */
.contact-form {
  display: flex; /* Flexbox layout */
  flex-direction: column; /* Vertical stacking */
  gap: 15px; /* Space between inputs */
  margin-top: 20px; /* Space above */
}

.contact-form input,
.contact-form textarea {
  padding: 10px; /* Inner padding */
  font-size: 1rem; /* Font size */
  border: none; /* No border */
  border-radius: 10px; /* Rounded corners */
  backdrop-filter: blur(5px); /* Background blur */
}

.contact-form input {
  background-color: rgba(252, 252, 252, 0.3); /* Input background */
}

.contact-form textarea {
  background-color: rgba(255, 255, 255, 0.3); /* Textarea background */
  resize: none; /* No resize option */
  height: 120px; /* Fixed height */
}

.contact-form button {
  background-color: #5c3c7b; /* Button color */
  color: #ffd105; /* Button text color */
  font-size: 1.2rem; /* Button font size */
  padding: 10px; /* Inner padding */
  border: none; /* No border */
  border-radius: 10px; /* Rounded corners */
  cursor: pointer; /* Pointer on hover */
  transition: background-color 0.3s ease; /* Transition effect */
}

.contact-form button:hover {
  background-color: #5c3c7b; /* Darker color on hover */
  color: #ffd105; /* Change text color on hover */
}

/* Contact Details Styles */
.contact-details {
  display: flex; /* Flexbox layout */
  flex-direction: column; /* Vertical stacking */
  align-items: flex-start; /* Align left */
  padding-left: 20px; /* Left padding */
}

.contact-details p {
  font-size: 1.1rem; /* Font size */
  color: #000; /* Text color */
  display: flex; /* Flexbox for icon and text */
  align-items: center; /* Center vertically */
  gap: 10px; /* Space between icon and text */
}

.contact-icon {
  font-size: 1.2rem; /* Icon size */
  color: #5c3c7b; /* Icon color */
}

/* Map Container Styles */
.map-container {
  margin-top: 20px; /* Space above */
  width: 100%; /* Full width */
  max-height: 190px; /* Maximum height */
  border-radius: 10px; /* Rounded corners */
  overflow: hidden; /* Hide overflow */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact-us-hero {
    height: 250px; /* Reduced height for mobile */
  }

  .hero-title {
    font-size: 2.5rem; /* Smaller title for mobile */
  }

  .hero-subtitle {
    font-size: 1.2rem; /* Smaller subtitle for mobile */
  }

  .glassmorphic-card h2 {
    font-size: 1.5rem; /* Smaller card title for mobile */
  }

  .container {
    grid-template-columns: 1fr; /* Stack cards in a single column */
  }

  .contact-form button {
    font-size: 1rem; /* Smaller button text for mobile */
  }
}

/* Additional media queries for screens with width 448px */
@media (max-width: 448px) {
  .contact-us-hero {
    height: 200px; /* Further reduced height for smaller screens */
    margin-top: 50px;
  }

  .hero-title {
    font-size: 2rem; /* Smaller title for very small screens */
  }

  .hero-subtitle {
    font-size: 1rem; /* Smaller subtitle for very small screens */
  }

  .glassmorphic-card h2 {
    font-size: 1.2rem; /* Smaller card title for very small screens */
  }
  
  .glassmorphic-card {
    max-width: 290px; /* Maximum width for small screens */
  }
  
  .contact-form input,
  .contact-form textarea {
    font-size: 0.9rem; /* Smaller font size for inputs */
  }
  
  .contact-form button {
    font-size: 0.9rem; /* Smaller button text for very small screens */
  }
}

/* Additional media queries for screens with width 375px */
@media (max-width: 375px) {
  .contact-us-hero {
    height: 180px; /* Further reduced height for 375px screens */
  }

  .hero-title {
    font-size: 1.8rem; /* Smaller title for 375px screens */
  }

  .hero-subtitle {
    font-size: 0.9rem; /* Smaller subtitle for 375px screens */
  }

  .glassmorphic-card h2 {
    font-size: 1.1rem; /* Smaller card title for 375px screens */
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 0.85rem; /* Smaller font size for inputs on 375px screens */
  }

  .contact-form button {
    font-size: 0.85rem; /* Smaller button text for 375px screens */
  }
}

/* Additional media queries for screens with width 320px */
@media (max-width: 320px) {
  .contact-us-hero {
    height: 160px; /* Further reduced height for very small screens */
  }

  .hero-title {
    font-size: 1.5rem; /* Smaller title for very small screens */
  }

  .hero-subtitle {
    font-size: 0.8rem; /* Smaller subtitle for very small screens */
  }

  .glassmorphic-card h2 {
    font-size: 1rem; /* Smaller card title for very small screens */
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 0.8rem; /* Smaller font size for inputs on very small screens */
  }

  .contact-form button {
    font-size: 0.8rem; /* Smaller button text for very small screens */
  }
}
