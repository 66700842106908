/* Testimonial Carousel Section */
.testimonial-section {
    padding: 50px;
    text-align: center;
    background-color: #ffd105; /* Background color */
    overflow: hidden; /* Prevents overflow beyond the screen */
    margin-bottom: 90px;
    margin-top: 70px;
}

.testimonial-title h2 {
    font-size: 2.5rem;
    color: #5c3c7b; /* Purple text for title */
    margin-bottom: 30px;
    font-family: 'Poppins', sans-serif;
}

.testimonial-carousel {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.testimonial-slider {
    display: flex;
    justify-content: flex-start; /* Align testimonials to the start */
    transition: transform 0.5s ease; /* Smooth transition for sliding */
    width: 100%; /* Keep slider within container */
    overflow: hidden; /* No content overflow */
    padding-left: 65px;
}

/* Testimonial Slide */
.testimonial-slide {
    flex: 0 0 30%; /* 30% width for 3 testimonials per view */
    max-width: 300px; /* Limit maximum width */
    background-color: #fff; /* White background for testimonials */
    padding: 20px;
    margin: 0 10px;
    border-radius: none; /* Increased border radius for smoother corners */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); /* Softer shadow */
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.testimonial-slide:hover {
    transform: translateY(-5px); /* Slight lift on hover */
}

.testimonial-slide p {
    font-size: 1.2rem; /* Increased font size for quotes */
    color: #333; /* Darker text for better readability */
    margin-bottom: 15px;
}

.testimonial-slide h4 {
    font-size: 1rem;
    color: #ffd105; /* Yellow text for client name */
    font-weight: bold;
}

/* Carousel Controls */
.carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(92, 60, 123, 0.7); /* Semi-transparent dark background */
    border: none;
    font-size: 2rem;
    cursor: pointer;
    z-index: 10;
    color: #ffd105; /* Yellow controls */
    padding: 15px; /* Adjust padding to make circular */
    border-radius: 50%; /* Circular buttons */
    width: 50px;
    height: 50px; /* Equal width and height for circle */
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-control:hover {
    background-color: rgba(92, 60, 123, 1); /* Full opacity on hover */
}

/* Positioning the arrows */
.carousel-control.prev {
    left: -50px;
}

.carousel-control.next {
    right: -50px;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .testimonial-slide {
        flex: 0 0 45%; /* Adjust to 2 testimonials per slide */
        border-radius: 20px; /* Adjusted radius for medium screens */
    }
}

@media (max-width: 768px) {
    .testimonial-slide {
        flex: 0 0 80%; /* 1 testimonial per slide on smaller screens */
        max-width: none; /* Remove max-width for smaller screens */
        align-items: center;
        border-radius: none; /* Slightly smaller radius for smaller screens */
    }
    .testimonial-carousel {
        padding-right: 35px;
    }
    .testimonial-title h2 {
        font-size: 35px;
    }
    .carousel-control.prev {
        left: -30px; /* Adjust for smaller screens */
    }
    .carousel-control.next {
        right: -40px; /* Adjust for smaller screens */
    }
}

@media (max-width: 480px) {
    .testimonial-title h2 {
        font-size: 2rem; /* Smaller title font size for mobile */
    }

    .testimonial-slide p {
        font-size: 1rem; /* Adjust quote font size for better readability */
        padding-right: 25px;
    }
    .testimonial-slider {
        margin-right: 10px;
        margin-left: 10px;
        width: 175px;
        height: 295px;
        align-items: center;
        border-radius: none;
    }
    .testimonial-slide {
        border-radius: none; /* Smallest border radius for mobile */
    }
    .carousel-control.next {
        margin-left: 30px;
        right: -30px;
    }
    .carousel-control.prev {
        left: 10px; /* Adjust for smaller screens */
    }
}
