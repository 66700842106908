.residential-page {
    font-family: 'Poppins', sans-serif;
}

/* Hero Section */
.hero-section h1 {
    font-size: 2.5rem; /* Reduced size for better mobile view */
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    margin: 20px 0; /* Added margin for spacing */
}

/* Breadcrumbs */
.breadcrumbs {
    padding: 15px;
    background-color: #f4f4f4;
    font-size: 14px;
    margin: 20px 0;
}

.breadcrumbs a {
    text-decoration: none;
    color: #5c3c7b;
}

/* Marquee */
.marquee {
    background-color: #ffd105;
    color: #5c3c7b;
    padding: 10px;
    font-weight: bold;
    margin-bottom: 40px;
    font-size: 1.2rem; /* Adjusted for readability on smaller screens */
}

/* Filter Section */
.filter-section {
    text-align: center;
    padding: 40px 20px;
    background-color: #f4f4f4;
}

.filter-section h2 {
    font-size: 1.8rem; /* Slightly smaller for mobile */
    color: #5c3c7b;
    margin-bottom: 20px;
}

.filter-inputs {
    display: flex;
    flex-direction: column; /* Stack inputs on smaller screens */
    align-items: center; /* Center align inputs */
    gap: 15px; /* Reduced gap */
    justify-content: center;
}

.filter-inputs input,
.filter-inputs select,
.filter-inputs button {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%; /* Full width for better usability */
    max-width: 300px; /* Limit max-width for larger screens */
}

.filter-inputs button {
    background-color: #5c3c7b;
    color: #fff;
}

/* Property List */
.property-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns */
    grid-template-rows: auto; /* Allow cards to wrap to the next row */
    gap: 10px; /* Space between cards */
    padding: 0px;
    justify-items: center; /* Center-aligns grid items */
    justify-content: center; /* Center-aligns the grid itself */
}

.property-card {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 3200px;
}

.property-card img {
    width: 100%;
    height: 350px; /* Increased height for larger display */
    object-fit: contain; /* Ensure the full image is visible without cropping */
    border-radius: 10px;
    margin-bottom: 15px;
}

.property-card h3 {
    color: #5c3c7b;
    font-size: 1.5rem;
}

.property-card p {
    color: #666;
    margin-bottom: 20px;
}

.view-details {
    background-color: #ffd105;
    padding: 10px 20px;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
}

.view-details:hover {
    background-color: #5c3c7b;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .hero-section h1 {
        font-size: 2rem; /* Smaller for tablet */
    }

    .property-list {
        grid-template-columns: repeat(2, 1fr); /* Two columns on smaller screens */
    }

    .filter-inputs {
        flex-direction: column; /* Stack inputs */
    }

    .filter-inputs input,
    .filter-inputs select,
    .filter-inputs button {
        max-width: 100%; /* Allow full width */
    }
}

@media (max-width: 480px) {
    .hero-section h1 {
        font-size: 1.5rem; /* Even smaller for mobile */
    }

    .property-list {
        grid-template-columns: 1fr; /* One column on very small screens */
        max-width: 80%;
    }

    .filter-section h2 {
        font-size: 1.5rem; /* Smaller heading */
    }
}
