.whatsapp-chat {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1000;
}

.whatsapp-background {
  background-color: white; /* White background circle */
  border-radius: 50%; /* Makes the div a perfect circle */
  padding: 15px; /* Adjust the size of the circle */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add some shadow for a 3D effect */
  transition: background-color 0.3s ease-in-out; /* Smooth transition */
}

/* Hover effect */
.whatsapp-background:hover {
  background-color: #ffd105; /* Yellow shade on hover */
}

.whatsapp-icon {
  color: #25d366; /* WhatsApp green */
  font-size: 30px; /* Adjust the icon size */
}

/* Change icon color on hover */
.whatsapp-background:hover .whatsapp-icon {
  color: black; /* Change icon color on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
  .whatsapp-background {
      padding: 12px; /* Slightly smaller padding on mobile */
  }

  .whatsapp-icon {
      font-size: 26px; /* Smaller icon size for mobile */
  }
}

@media (max-width: 480px) {
  .whatsapp-background {
      padding: 10px; /* Further reduce padding for smaller screens */
  }

  .whatsapp-icon {
      font-size: 22px; /* Further reduce icon size */
  }
}
